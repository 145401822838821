import { SECTION_TYPE } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/constant';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import LogoAboutUs from './components/LogoAboutUs';
import RenderLinksList from './components/RenderLinksList';
import ContactUs from './components/ContactUs';
import NewsLetter from './components/NewsLetter';
import { useEffect, useRef, useState } from 'react';
import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';
import CategoryCollections from './components/CategoryCollections';
import CustomHTMLSection from './components/CustomHTML';
import TextGroupSection from './components/TextGroupSection';
import { CopyrightAndPayment } from './components/CopyrightAndPayment';
import { FooterGetIcon } from './components/svg';
import { twMerge } from 'tailwind-merge';
import { PATH } from 'src/utils/routes';
import { normalizeFooterConfig } from './utils';

const NewFooterComponent = (props: any) => {
  const isMobile = isMobileDevice();
  const {
    widgetContextState: { activeFooterData },
    widgetContextActions: { setActiveFooterData },
  } = useWidgetDndContextData();
  const [iconOpacity, setIconOpacity] = useState(0);
  const [isFooterInView, setIsFooterInView] = useState(false);

  const footerConfig = activeFooterData?.footerPropsConfig;
  const footerConfiguration = activeFooterData?.footerPropsConfig?.footerConfig;
  const color =
    footerConfiguration?.colorSchemes?.[footerConfiguration?.selectedColorSchemeIndex]
      ?.text;
  const backgroundColor =
    footerConfiguration?.colorSchemes?.[footerConfiguration?.selectedColorSchemeIndex]
      ?.background;
  const footerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsFooterInView(entry?.isIntersecting);
        if (entry.isIntersecting) {
          const visibleRatio = Number(entry.intersectionRatio?.toFixed(1));
          if (visibleRatio === 0.2) {
            setIconOpacity(0);
          }
          if (visibleRatio === 0.3) {
            setIconOpacity(1);
          }
        }
      },
      {
        root: null,
        threshold: Array.from({ length: 4 }, (_, i) => i * 0.1),
      }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  // if(IS_SERVER){
  //   setDataToContext()
  // }

  useEffect(() => {
    if (!window.location.pathname.includes(PATH.WEBSITE_BUILDER)) {
      setDataToContext();
    }
  }, [props?.config]);

  function setDataToContext() {
    if (props.config) {
      setActiveFooterData((data) => ({
        ...data,
        footerName: props.name,
        footerPropsConfig: normalizeFooterConfig(props.config),
      }));
    }
  }

  function handleGoToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  function checkForLayerVisibility(layerData, layerList, layerName) {
    const elementVisibility = layerData?.filter((layerData) => layerData?.isVisible);
    if (layerList?.length === 2 && layerName === 'layer2') {
      return false;
    }
    if (layerList?.length === 3 && layerName === 'layer3') {
      return false;
    }
    return elementVisibility?.length > 0;
  }

  // function getColumnGap() {
  //   // Percentage count
  //   let totalWidthCount = 0;
  //   let widgetCount = 0;
  //   footerConfig?.layer1?.data?.forEach((compData) => {
  //     if (compData?.isVisible) {
  //       totalWidthCount += Number(compData?.style?.width?.replace('%', ''));
  //       widgetCount++;
  //     }
  //   });
  //   if (totalWidthCount >= 100) {
  //     return '80px';
  //   } else {
  //     const totalWidth = footerRef?.current?.clientWidth - 80;
  //     const actualGap = (totalWidth - (totalWidth / 100) * totalWidthCount) / widgetCount;
  //     if (actualGap > 120) {
  //       return '120px';
  //     } else if (actualGap < 80) {
  //       return '80px';
  //     } else {
  //       return `${actualGap}px`;
  //     }
  //   }
  // }

  function renderLayers() {
    const layerList = Object.keys(footerConfig)?.filter((item) =>
      item.startsWith('layer')
    );
    return layerList
      .filter((layerName) => {
        const layer = footerConfig[layerName];
        if (layer?.isStaticSection) {
          return false;
        }
        const layerData = layer?.builder || [];
        const actualLayer = footerConfig[layerName]?.actualLayer;
        const isRowVisible = checkForLayerVisibility(layerData, layerList, actualLayer);
        return isRowVisible;
      })
      .map((layerName) => {
        const actualLayer = footerConfig[layerName]?.actualLayer;
        const layerData = footerConfig[layerName]?.builder || [];
        const isLayer1 = actualLayer === 'layer1';

        return (
          <div
            key={layerName}
            style={{
              gap: !isMobile && layerName !== 'layer2' ? '40px' : '',
            }}
            className={twMerge(
              isMobile ? 'tw-flex tw-flex-col tw-items-center tw-gap-[28px]' : 'tw-grid',
              // 238px (instead of 180px) can be taken as maxiumun min-width of a column in this case newsletter
              isLayer1 ? 'tw-grid-cols-[repeat(auto-fit,minmax(180px,auto))]' : '',
              actualLayer === 'layer2'
                ? isMobile
                  ? 'tw-gap-[28px]'
                  : 'tw-gap-[60px]'
                : ''
            )}
          >
            {layerData?.map((componentData, index) => {
              const type = componentData?.type;
              if (!componentData?.isVisible) {
                return null;
              }
              switch (type) {
                case SECTION_TYPE.LOGO_ABOUTUS: {
                  return <LogoAboutUs layer={layerName} index={index} />;
                }
                case SECTION_TYPE.QUICK_LINKS: {
                  return <RenderLinksList layer={layerName} index={index} />;
                }
                case SECTION_TYPE.CONTACTUS_LOCATION: {
                  return <ContactUs layer={layerName} index={index} />;
                }
                case SECTION_TYPE.NEWS_LETTER: {
                  return <NewsLetter layer={layerName} index={index} />;
                }
                case SECTION_TYPE.CATEGORIES_COLLECTIONS: {
                  return (
                    <CategoryCollections
                      layer={layerName}
                      index={index}
                      fontColor={color}
                    />
                  );
                }
                case SECTION_TYPE.CUSTOM_HTML: {
                  return <CustomHTMLSection layer={layerName} index={index} />;
                }
                case SECTION_TYPE.TEXT_GROUP: {
                  return (
                    <TextGroupSection layer={layerName} index={index} fontColor={color} />
                  );
                }
              }
            })}
          </div>
        );
      });
  }

  function renderGotoTopIcon() {
    return (
      <div
        style={{ opacity: iconOpacity, transition: 'opacity 0.5s ease' }}
        className={`tw-sticky tw-flex tw-cursor-pointer tw-justify-end ${!isMobile ? 'tw-bottom-[92px] tw-mr-[40px]' : 'tw-bottom-[20px] tw-mr-[20px]'}`}
        onClick={() => handleGoToTop()}
      >
        <FooterGetIcon color={color} backgroundColor={backgroundColor} />
      </div>
    );
  }

  return (
    <footer
      ref={footerRef}
      style={{
        background: backgroundColor,
        color: color,
      }}
      className="tw-relative tw-w-[100%]"
    >
      <div className="tw-mx-auto tw-max-w-[1600px]">
        <div
          className={`${isMobile ? 'tw-px-[20px] tw-pt-[40px]' : 'tw-px-[40px] tw-pt-[72px]'} tw-flex tw-flex-col tw-gap-[60px] tw-pb-[38px]`}
        >
          {renderLayers()}
        </div>
        <CopyrightAndPayment textColor={color} isFooterInView={isFooterInView} />
        {footerConfiguration?.showGoToTop && renderGotoTopIcon()}
      </div>
    </footer>
  );
};

export default NewFooterComponent;
