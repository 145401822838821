import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { EditableText } from '../../RichTextImage/component/TextEditor';
import { useSSRSelector } from 'src/redux/ssrStore';
import FooterMap2 from 'src/components/FooterMap/FooterMap2';
import CustomizedButton from 'src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import { getButtonVariantsData } from '../../Carousel/utils';
import { btnActionHandler } from 'src/components/DesignSystem/ModernTheme/utils/btnUtils';
import { BUTTON_SIZE_TYPE } from 'src/components/WidgetMaker/utils/buttonConstant';
import { ICON_COMPONENT } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/constant';
import SocialMediaIcons from './SocialMediaicon';
import RenderAppDownload from './RenderAppDownload';
import { useState } from 'react';
import { AccordionComponent } from './Accordion';
import { FOOTER_CHILDREN_SECTION_CONFIGS } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/NewFooterConfig';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';

function getIcon(color: string) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <g id="Group 1171275726">
        <path
          id="Vector"
          d="M4.33334 1.5V2.83334H8.72334L1 10.5567L1.94334 11.5L9.66666 3.77666V8.16666H11V1.5H4.33334Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

const ContactUs = (props: any) => {
  const { layer, index } = props;
  const isMobile = isMobileDevice();
  const {
    widgetContextState: { activeFooterData },
  } = useWidgetDndContextData();
  const store = useSSRSelector((state) => state.storeReducer.store);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const { storeAddress, storePhone, storeEmail } = useStoreContactDetails();
  const iconsData = activeFooterData?.footerPropsConfig?.[layer]?.builder?.find(
    (subWidget) => subWidget?.type === ICON_COMPONENT.LOGO
  );
  const appDownload = iconsData?.section?.[2];
  const socialMedia = iconsData?.section?.[3];
  const footerConfig = activeFooterData?.footerPropsConfig?.footerConfig;
  const color =
    footerConfig?.colorSchemes?.[footerConfig?.selectedColorSchemeIndex]?.text;
  const componentData = activeFooterData?.footerPropsConfig?.[layer]?.builder?.[index];

  function renderHeading(heading) {
    return <EditableText content={heading} className="tw-font-[500]"></EditableText>;
  }

  function renderContactUs() {
    const { contentContact } = componentData;
    return (
      <div className="tw-flex tw-flex-col tw-gap-[16px]">
        {!isMobile && renderHeading(contentContact)}
        <div className="tw-flex tw-flex-col tw-text-[14px] tw-font-normal tw-leading-[22.4px]">
          {!!storePhone && (
            <a
              className="tw-cursor-pointer tw-text-inherit tw-no-underline"
              href={`tel:${storePhone}`}
            >
              {storePhone}
            </a>
          )}
          {!!storeEmail && (
            <a
              className="tw-cursor-pointer tw-text-inherit tw-no-underline"
              href={`mailto:${storeEmail}`}
            >
              {storeEmail}
            </a>
          )}
        </div>
      </div>
    );
  }

  function renderButton() {
    const { button } = componentData;
    return (
      <div className="tw-mt-[12px]">
        {button?.map((buttonData, btnIndex) => {
          const buttonVariant = getButtonVariantsData(buttonData);
          const selectedButtonType = buttonVariant[buttonData.type];
          return buttonData.isShow ? (
            <div className="tw-flex tw-gap-[8px]">
              <CustomizedButton
                key={btnIndex}
                size={BUTTON_SIZE_TYPE.LARGE}
                btnType={buttonData.type}
                buttonData={buttonData}
                selectedButtonData={selectedButtonType}
                variant={selectedButtonType.variant}
                onClick={() => btnActionHandler(buttonData?.actions, () => {})}
                className="!tw-items-start !tw-justify-start"
              >
                {buttonData?.text}
              </CustomizedButton>
              <div>{getIcon(buttonData?.btnStyleConfig?.textColor)}</div>
            </div>
          ) : null;
        })}
      </div>
    );
  }

  function renderLocation() {
    const { contentLocation } = componentData;

    return (
      <div className="tw-flex tw-flex-col">
        {!isMobile && renderHeading(contentLocation)}
        <div
          className={`tw-flex tw-flex-col tw-gap-[8px] tw-text-[14px] tw-font-normal tw-leading-[22.4px] ${!isMobile ? 'tw-mt-[16px]' : ''}`}
        >
          {storeAddress}
        </div>
        {renderButton()}
      </div>
    );
  }

  function renderMap() {
    return <FooterMap2 storeData={store} height={'200'} />;
  }

  function socialMediaIcons() {
    return (
      <div className="tw-mb-[24px]">
        <SocialMediaIcons
          componentData={socialMedia}
          activeFooterData={activeFooterData}
        />
      </div>
    );
  }

  function appDownloadIcons() {
    return (
      <div className="">
        <RenderAppDownload
          componentData={appDownload}
          activeFooterData={activeFooterData}
        />
      </div>
    );
  }

  function renderLocationAndOther() {
    return (
      <div className={`tw-flex tw-flex-col`}>
        <div className="tw-mb-[36px] tw-flex tw-flex-col tw-gap-[20px]">
          {renderLocation()}
          {componentData?.showMap && <div className="mt-[16px]">{renderMap()}</div>}
        </div>
        {socialMedia?.columnType === ICON_COMPONENT.CONTACT &&
          socialMedia?.isVisible &&
          socialMediaIcons()}
        {appDownload?.columnType === ICON_COMPONENT.CONTACT &&
          appDownload?.isVisible &&
          appDownloadIcons()}
      </div>
    );
  }

  function renderAccordionComp() {
    const { contentContact, contentLocation } = componentData;
    return (
      <div className="tw-flex tw-flex-col tw-gap-[28px]">
        {componentData?.showContact && (
          <AccordionComponent
            headingComp={() => renderHeading(contentContact)}
            contentComp={renderContactUs}
            color={color}
            isOpen={isOpenContact}
            setIsOpen={() => setIsOpenContact((prev) => !prev)}
          />
        )}
        {componentData?.showLocation && (
          <AccordionComponent
            headingComp={() => renderHeading(contentLocation)}
            contentComp={renderLocationAndOther}
            color={color}
            isOpen={isOpenLocation}
            setIsOpen={() => setIsOpenLocation((prev) => !prev)}
          />
        )}
      </div>
    );
  }

  return (
    <div
      style={
        isMobile
          ? { width: '100%' }
          : FOOTER_CHILDREN_SECTION_CONFIGS[componentData?.type].style || {}
      }
      className="tw-flex tw-flex-col tw-gap-[36px]"
    >
      {!isMobile && componentData?.showContact && renderContactUs()}
      {!isMobile && componentData?.showLocation && renderLocationAndOther()}
      {isMobile && renderAccordionComp()}
    </div>
  );
};

export default ContactUs;
