import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { EditableText } from '../../RichTextImage/component/TextEditor';
import { FOOTER_CHILDREN_SECTION_CONFIGS } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/NewFooterConfig';

const TextGroupSection = (props: any) => {
  const { layer, index, fontColor } = props;
  const {
    widgetContextState: { activeFooterData },
  } = useWidgetDndContextData();
  const componentData = activeFooterData?.footerPropsConfig?.[layer]?.builder?.[index];

  return (
    <div style={FOOTER_CHILDREN_SECTION_CONFIGS[componentData?.type].style || {}}>
      <EditableText
        content={componentData?.content?.heading}
        className="tw-font-[500]"
        style={{ color: fontColor }}
      />
      <div
        dangerouslySetInnerHTML={{ __html: componentData.content?.description }}
        className="tw-mt-[8px] tw-break-all [&>*]:tw-text-[14px]"
      />
    </div>
  );
};

export default TextGroupSection;
