const LINK_TYPE = {
  COLLECTION: 'collection',
  CATEGORY: 'category',
};

export function getMergedShopSectionData(
  presentLinks,
  collectionCategoriesList,
  categoriesList
) {
  if (!presentLinks) {
    return [];
  }
  const showCollectionLink = !!Object.keys(collectionCategoriesList || {})?.length;
  const linkTypeToSync = showCollectionLink ? LINK_TYPE.COLLECTION : LINK_TYPE.CATEGORY;
  const linksToSync = showCollectionLink ? collectionCategoriesList : categoriesList;

  const allShopLinks = getCollCatData(linksToSync, linkTypeToSync);

  // newly added links
  const newShopLinks = allShopLinks?.filter(
    (link) => !presentLinks.some((existingLink) => existingLink?.id === link?.id)
  );

  // remove deleted links
  presentLinks = presentLinks?.filter(
    (link) => link?.linkType !== linkTypeToSync || (link?.id && !!linksToSync[link?.id])
  );

  return [...presentLinks, ...newShopLinks];
}

function getCollCatData(data: Record<string, Record<string, any>>, type: string) {
  const keysList = Object.keys(data || []);
  const updatedList = keysList?.map((key) => {
    return {
      linkType: type,
      id: key,
      ...data[key],
    };
  });
  return updatedList;
}
