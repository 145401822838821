import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { EditableText } from '../../RichTextImage/component/TextEditor';
import { allowEverything } from 'src/utils/regex';
import { useState } from 'react';
import CustomTextField from 'src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/TextfieldBox';
import CustomizedButton from 'src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import { BUTTON_SIZE_TYPE } from 'src/components/WidgetMaker/utils/buttonConstant';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmailError } from 'src/utils/validateEmailError';
import { LEAD_GENERATION_TYPES } from 'src/utils/constants';
import { setLeadData } from 'src/utils/localStorageFunctions';
import { updateCustomerLead } from 'src/redux/actions';
import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';
import { ICON_COMPONENT } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/constant';
import SocialMediaIcons from './SocialMediaicon';
import RenderAppDownload from './RenderAppDownload';
import { FOOTER_CHILDREN_SECTION_CONFIGS } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/NewFooterConfig';

function getIcon(type, color, bgColor) {
  switch (type) {
    case 'type1': {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 40 40"
          fill="none"
        >
          <g id="Group 1171275747">
            <rect id="Rectangle 43574" width="40" height="40" fill={color} />
            <path
              id="Vector 9"
              d="M10 20H29M29 20L23.3 14M29 20L23.3 26"
              stroke={bgColor}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      );
    }
    case 'type3': {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="10"
          viewBox="0 0 17 10"
          fill="none"
        >
          <path
            id="Vector 9"
            d="M1 5H16M16 5L11.5 0.5M16 5L11.5 9.5"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    }
  }
}

const NewsLetter = (props: any) => {
  const { layer, index } = props;
  const {
    widgetContextState: { activeFooterData },
  } = useWidgetDndContextData();
  const isMobile = isMobileDevice();
  const { leadgenInfo, userData, store } = useSelector((state: any) => ({
    store: state.storeReducer.store,
    leadgenInfo: state.leadGenReducer.leadgenInfo,
    userData: state.userReducer.data,
  }));
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [leadError, setLeadError] = useState(false);
  const [successMesssage, setSuccessMessage] = useState('');
  const componentData = activeFooterData?.footerPropsConfig?.[layer]?.builder?.[index];
  const footerConfig = activeFooterData?.footerPropsConfig?.footerConfig;
  const color =
    footerConfig?.colorSchemes?.[footerConfig?.selectedColorSchemeIndex]?.text;
  const bgColor =
    footerConfig?.colorSchemes?.[footerConfig?.selectedColorSchemeIndex]?.background;
  const iconsData = activeFooterData?.footerPropsConfig?.[layer]?.builder?.find(
    (subWidget) => subWidget?.type === ICON_COMPONENT.LOGO
  );
  const appDownload = iconsData?.section?.[2];
  const socialMedia = iconsData?.section?.[3];

  const submitLeadInput = () => {
    const phoneReg = /(6|7|8|9)\d{9}/;
    const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const payload = {
      store_id: store?.store_id,
      lead_user_phone: '',
      lead_email_id: '',
      user_phone: userData?.phone,
    };
    if (leadgenInfo?.type === LEAD_GENERATION_TYPES.PHONE)
      payload['lead_user_phone'] = searchText;
    else payload['lead_email_id'] = searchText;
    if (payload['lead_email_id']) {
      const status = validateEmailError(payload['lead_email_id']);
      if (!status) {
        return;
      }
    }
    const callback = () => {
      setSuccessMessage('Thanks for subscribing!');
      setLeadData(searchText);
      setSearchText('');
      setTimeout(() => setSuccessMessage(''), 10000);
    };

    if (
      leadgenInfo?.type === LEAD_GENERATION_TYPES.PHONE &&
      searchText.length === 10 &&
      searchText.match(phoneReg)
    ) {
      dispatch(updateCustomerLead(payload, callback));
      setLeadError(false);
    } else if (searchText.match(emailReg)) {
      setLeadError(false);
      dispatch(updateCustomerLead(payload, callback));
    } else setLeadError(true);
  };

  function renderHeading(content) {
    return <EditableText content={content} className="tw-font-[500]"></EditableText>;
  }

  const getInputPlaceholer = () => {
    if (leadgenInfo?.type === LEAD_GENERATION_TYPES.PHONE) return 'Enter your phone';
    return 'Enter your e-mail id';
  };

  const renderError = () => {
    return (
      <div className="error-msg flex  f7 red  flex justify-start items-center  mt2">
        <span
          className="ba b--red flex justify-center items-center br-pill bg-red white mr2 error-icon"
          style={{ fontSize: 8, padding: '2px 6px' }}
        >
          {' '}
          !{' '}
        </span>

        {`Please enter a valid ${
          leadgenInfo?.type === LEAD_GENERATION_TYPES.EMAIL ? 'email ID' : 'phone number'
        }`}
      </div>
    );
  };

  function renderHeadingDescription() {
    return (
      <div className="tw-flex tw-flex-col tw-gap-[16px]">
        {renderHeading(componentData?.contentHeading)}
        {renderHeading(componentData?.contentDescription)}
      </div>
    );
  }

  function renderRightIcon(noMargin: boolean = true) {
    return (
      <div
        onClick={submitLeadInput}
        className={`tw-flex tw-cursor-pointer tw-items-center tw-justify-center`}
      >
        {getIcon('type3', color, bgColor)}
      </div>
    );
  }

  function renderRightButton() {
    return (
      <div
        onClick={submitLeadInput}
        className={`tw-mr-[20px] tw-flex tw-w-[100px] tw-max-w-[100px] tw-cursor-pointer tw-items-center tw-justify-end tw-text-[13px] tw-font-medium`}
      >
        {componentData?.subscribeText || ''}
      </div>
    );
  }

  function renderRightIconBox() {
    return (
      <div
        onClick={submitLeadInput}
        className={`tw-my-[8px] tw-mr-[10px] tw-flex tw-cursor-pointer tw-items-center tw-justify-center`}
      >
        {getIcon('type1', color, bgColor)}
      </div>
    );
  }

  function renderInputField(type) {
    switch (type) {
      case 'type1': {
        return (
          <CustomTextField
            parentClass="tw-w-[100%]"
            placeholder={getInputPlaceholer()}
            regexCheck={allowEverything}
            value={searchText}
            customClass="!tw-pl-[20px] !tw-py-[2px] tw-justify-center tw-truncate tw-w-[100%]"
            showErrorBox={false}
            handleChange={(value: any) => setSearchText(value)}
            rows={1}
            borderColor={color}
            customPadding={'10px 0px'}
            inputColor={color}
            rightIconCompo={() => renderRightIconBox()}
          />
        );
      }
      case 'type2': {
        return (
          <div className="tw-flex tw-flex-col tw-gap-[12px]">
            <CustomTextField
              parentClass="tw-w-[100%]"
              placeholder={getInputPlaceholer()}
              regexCheck={allowEverything}
              value={searchText}
              customClass="!tw-px-[20px] !tw-py-[2px] tw-justify-center tw-truncate tw-w-[100%]"
              showErrorBox={false}
              handleChange={(value: any) => setSearchText(value)}
              rows={1}
              borderColor={color}
              customPadding={'10px 0px'}
              inputColor={color}
            />
            <CustomizedButton
              size={BUTTON_SIZE_TYPE.LARGE}
              btnType={'solid'}
              variant={'secondary'}
              onClick={() => {}}
              backgroundColor={color}
              color={bgColor}
              className="tw-w-max tw-cursor-pointer !tw-items-start !tw-justify-start tw-px-[20px] tw-py-[10px] !tw-text-[13px] !tw-font-medium"
            >
              {componentData?.subscribeText || ''}
            </CustomizedButton>
          </div>
        );
      }
      case 'type3': {
        return (
          <div style={{ borderBottom: `1px solid ${color}` }}>
            <CustomTextField
              parentClass="tw-w-[100%]"
              placeholder={getInputPlaceholer()}
              regexCheck={allowEverything}
              value={searchText}
              customClass="!tw-py-[2px] tw-justify-center tw-truncate tw-w-[100%]"
              showErrorBox={false}
              handleChange={(value: any) => setSearchText(value)}
              rows={1}
              hideBorder
              customPadding={'10px 0px'}
              inputColor={color}
              rightIconCompo={() => renderRightIcon(false)}
            />
          </div>
        );
      }
      case 'type4': {
        return (
          <CustomTextField
            parentClass="tw-w-[100%]"
            placeholder={getInputPlaceholer()}
            regexCheck={allowEverything}
            value={searchText}
            customClass="!tw-pl-[20px] !tw-py-[2px] tw-justify-center tw-truncate tw-w-[100%]"
            showErrorBox={false}
            handleChange={(value: any) => setSearchText(value)}
            rows={1}
            borderColor={color}
            customPadding={'10px 0px'}
            inputColor={color}
            rightIconCompo={() => renderRightButton()}
          />
        );
      }
    }
  }

  function renderSuccessMessage() {
    return (
      <span className="tw-text-[14px] tw-font-medium tw-text-green-400">
        {successMesssage}
      </span>
    );
  }

  function socialMediaIcons() {
    return (
      <div className="tw-mb-[24px]">
        <SocialMediaIcons
          componentData={socialMedia}
          activeFooterData={activeFooterData}
        />
      </div>
    );
  }

  function appDownloadIcons() {
    return (
      <div className="">
        <RenderAppDownload
          componentData={appDownload}
          activeFooterData={activeFooterData}
        />
      </div>
    );
  }

  return (
    <div
      style={
        isMobile
          ? { width: '100%' }
          : FOOTER_CHILDREN_SECTION_CONFIGS[componentData?.type].style || {}
      }
      className=""
    >
      <div className="tw-mb-[36px] tw-flex tw-flex-col tw-gap-[24px]">
        {renderHeadingDescription()}
        <div className="tw-flex tw-flex-col tw-gap-[8px]">
          {renderInputField(componentData?.newsLetterType)}
          {leadError && renderError()}
          {successMesssage?.length > 0 && renderSuccessMessage()}
        </div>
      </div>
      {socialMedia?.columnType === ICON_COMPONENT.NEWS_LETTER &&
        socialMedia?.isVisible &&
        socialMediaIcons()}
      {appDownload?.columnType === ICON_COMPONENT.NEWS_LETTER &&
        appDownload?.isVisible &&
        appDownloadIcons()}
    </div>
  );
};

export default NewsLetter;
