import { getRegisteredWidget } from '@dotpe/kui/styles/registerWidgetForDnD';
import { FOOTER_CHILDREN_SECTION_CONFIGS } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/NewFooterConfig';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';

const CustomHTMLSection = (props: any) => {
  const { layer, index } = props;
  const {
    widgetContextState: { activeFooterData },
  } = useWidgetDndContextData();
  const componentData = activeFooterData?.footerPropsConfig?.[layer]?.builder?.[index];

  const HTMLComponent = getRegisteredWidget('HTML')?.component;
  if (!HTMLComponent) {
    return null;
  }
  return (
    <div style={FOOTER_CHILDREN_SECTION_CONFIGS[componentData?.type].style || {}}>
      <HTMLComponent
        mode="view"
        container={componentData?.contentProps}
        value={componentData?.content}
      />
    </div>
  );
};

export default CustomHTMLSection;
